// File with global styles

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box; 
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
`;

export default GlobalStyle;
