import React from "react";
import { PageWrapper } from "../styles/utility.css";
import { PortfolioWrapper, Project, Title, IFrameContainer } from "../styles/portfolio.css";

const Portfolio = () => {
    return (
        <PageWrapper position="sticky" top="0" backgroundColor="rgb(0,0,0, 0.9);" height={"unset"}>
            <Title>portfolio</Title>
            <PortfolioWrapper>
                <Project>
                    <IFrameContainer>
                        <iframe
                            src="https://player.vimeo.com/video/820131821?h=2ab7bcbd33&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: "none"
                            }}
                            title="distortion - trailer"
                        ></iframe>
                    </IFrameContainer>
                    <p>
                        distortion - trailer
                        <br /> gaffer
                    </p>
                </Project>
                <Project>
                    <IFrameContainer>
                        <iframe
                            src="https://player.vimeo.com/video/714786050?h=682a5576cb&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: "none"
                            }}
                        ></iframe>
                    </IFrameContainer>
                    <p>
                        self-portrait <br /> camera; editing
                    </p>
                </Project>
            </PortfolioWrapper>
        </PageWrapper>
    );
};

export default Portfolio;
