import styled, { keyframes, css } from "styled-components";

import { InfoSection, InfoParagraph, media, FONT_COLOR } from "./utility.css";
import BackgroundImage from "gatsby-background-image";

const appear = keyframes`
  0% { 
    filter: blur(20px);
  }

  100% { 
    filter: blur(0px);
  }
`;
const smoothInLeft = keyframes`
  0% { 
    opacity: 0.5;  
    left: -250px;
  }

  100% { 
    left: 0px 
    opacity: 1; 
  }
`;
const smoothInRight = keyframes`
  0% { 
    opacity: 0.5;  
    right: -250px
   
  }
  100% { 
    right: 0px 
    opacity: 1; 
   }
`;
export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: auto;
`;
export const Name = styled.section`
    ${InfoSection()};
    p {
        ${InfoParagraph({ fontSize: "15px" })};
        left: 0;
        position: absolute;
        animation: ${(props) =>
            props.mounted &&
            css`
                ${smoothInLeft} 0.8s ease-out
            `};

        ${media.phone} {
            max-width: 100px;
        }
    }
`;

export const JobTitle = styled.section`
    ${InfoSection()};
    p {
        ${InfoParagraph({ fontSize: "12px" })};
        position: absolute;
        bottom: 10%;
        left: 0;
        animation: ${(props) =>
            props.mounted &&
            css`
                ${smoothInLeft} 0.8s ease-out
            `};

        ${media.phone} {
            top: 35%;
        }
    }
`;
export const Contact = styled.section`
    ${InfoSection()};
    p {
        ${InfoParagraph({ fontSize: "12px" })};
        position: absolute;
        bottom: 10%;
        right: 0;
        animation: ${(props) =>
            props.mounted &&
            css`
                ${smoothInRight} 0.8s ease-out
            `};

        ${media.tablet} {
            color: white;
        }

        ${media.phone} {
            top: 20%;
            text-align: right;
            color: ${FONT_COLOR};
        }
    }
`;

export const SocialMedia = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    padding: 2rem;
    animation: ${(props) =>
        props.mounted &&
        css`
            ${smoothInRight} 0.8s ease-out
        `};

    ${media.phone} {
    }

    div {
        padding-left: 1.5rem;
        svg {
            opacity: 1;
            transition: opacity 0.2s ease-in;
        }

        svg:hover {
            opacity: 0.6;
        }
    }
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
    &::after {
        animation: ${appear} 0.5s ease-out;
    }
`;
