import styled, { css } from "styled-components";

export const FONT_COLOR = "#5c2536";
export const WHITE = "#fff";
export const BACK_COLOR = "#151515";

const height = (props) => (props.height ? props.height : "100vh");
const width = (props) => (props.width ? props.width : "100%");
const top = (props) => (props.top ? props.top : "unset");
const position = (props) => (props.position ? props.position : "unset");
const backgroundColor = (props) => (props.backgroundColor ? props.backgroundColor : "unset");

export const PageWrapper = styled.div`
    height: ${height};
    width: ${width};
    top: ${top};
    position: ${position};
    background: ${backgroundColor};
    background-size: cover;
    background-repeat: no-repeat;
    scroll-behavior: smooth;
`;
export const CenterBox = () => css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
export const InfoParagraph = ({ fontSize = "12px" }) => css`
    font-family: urbane, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: ${fontSize};
    letter-spacing: 3px;
    color: ${FONT_COLOR};
    padding: 2rem;
    margin: 0;
`;
export const InfoSection = () => css`
    height: 50vh;
    width: 50%;
`;

const customMediaQuery = (maxWidth) => `@media (max-width: ${maxWidth}px)`;

export const media = {
    custom: customMediaQuery,
    desktop: customMediaQuery(922),
    tablet: customMediaQuery(768),
    phone: customMediaQuery(576)
};
