import * as React from "react";
import Seo from "../components/seo";
import GlobalStyle from "../styles/global.css.js";

import Home from "../components/home";
import Portfolio from "../components/portfolio";

const IndexPage = () => {
    return (
        <>
            <GlobalStyle />
            <Home></Home>
            <Portfolio></Portfolio>
        </>
    );
};

export default IndexPage;

//   {/* <Seo title="Home" /> */}
