import React, { useEffect, useState } from "react";

import { PageWrapper } from "../styles/utility.css";
import { Contact, JobTitle, Container, SocialMedia, Name, StyledBackgroundImage } from "../styles/home.css";
import { useStaticQuery, graphql } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faVimeo, faBehance } from "@fortawesome/free-brands-svg-icons";

const iconStyle = {
    width: "30px",
    height: "30px",
    color: "#5c2536"
};

const Home = () => {
    const [isMounted, setIsMounted] = useState(false); // Need this for the react-tooltip

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "me.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1366) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const imageData = data.desktop.childImageSharp.fluid;

    return (
        <PageWrapper position="sticky" top="0" backgroundColor="#9aaebf" height="90vh">
            {isMounted && (
                <StyledBackgroundImage
                    Tag="section"
                    preserveStackingContext={true}
                    fluid={imageData}
                    backgroundColor={`#9aaebf`}
                    fadeIn={true}
                    mounted={isMounted}
                >
                    <Container>
                        <Name mounted={isMounted}>
                            <p>matylda horodyska</p>
                        </Name>
                        <SocialMedia mounted={isMounted}>
                            <div>
                                <a href="https://www.instagram.com/experimental_ambient_visuals/">
                                    <FontAwesomeIcon icon={faInstagram} style={iconStyle} />
                                </a>
                            </div>
                            <div>
                                <a href="https://www.behance.net/matyldahor">
                                    <FontAwesomeIcon icon={faBehance} style={iconStyle} />
                                </a>
                            </div>
                            <div>
                                <a href="https://vimeo.com/user101344995">
                                    <FontAwesomeIcon icon={faVimeo} style={iconStyle} />
                                </a>
                            </div>
                        </SocialMedia>
                        <JobTitle mounted={isMounted}>
                            <p>
                                gaffer <br />
                                camera operator <br />
                                photographer <br />
                            </p>
                        </JobTitle>

                        <Contact mounted={isMounted}>
                            <p> mhorodyska@protonmail.com</p>
                        </Contact>
                    </Container>
                </StyledBackgroundImage>
            )}
        </PageWrapper>
    );
};

export default Home;
