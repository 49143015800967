import styled from "styled-components";
import { CenterBox, media, WHITE } from "./utility.css";

export const Title = styled.div`
    text-align: center;
    color: ${WHITE};
    height: 100px;
    font-family: urbane, sans-serif;
    font-weight: 600;
    font-size: 12px;
    font-style: normal;
    letter-spacing: 3px;
    padding-top: 1rem;
`;

export const PortfolioWrapper = styled.div`
    position: relative;
`;
export const Images = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 3rem;
`;

export const IFrameContainer = styled.div`
    padding: 26.25% 0 0 0;
    position: relative;

    ${media.phone} {
        padding: 46.25% 0 0 0;
    }
`;
export const Project = styled.div`
    padding: 4rem 0rem;
    // ${CenterBox()};
    // background: transparent;
    // z-index: 1;
    // line-height: 30px;
    // width: 100%;
    // height: 75px;
    //  text-align: center;

    p {
        position: absolute;
        left: 26.7%;
        font-family: urbane, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 12px;
        letter-spacing: 2px;
        color: #f0f0f0;
        // transition: background 0.2s ease-in;
        text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.8);

        ${media.phone} {
            left: 10%;
        }
    }
`;
